import Swiper from "swiper";
import VanillaTilt from 'vanilla-tilt'
import { state } from '../common/_variables'

const created = () => {};

const mounted = () => {
    /**
     * headerが降りてくるアニメーション
     */
    $('.ec-layoutRole').addClass('animation')


    // visual
    new Swiper(".ec-keyVisualRole__swiper", {
        loop: true, //ループさせる
        speed: 2000, //２秒かけながら移動
        parallax: true, //パパラックスさせる
        allowTouchMove: false, //pcではスライプの禁止、クリックでの移動を禁止する
        loopAdditionalSlides: 10, //ループのときに作られるクローンの枚数。
        autoplay: {
            delay: 4000, //3秒後に次のスライドへ
            disableOnInteraction: false, //ユーザー側で操作してもスライドを止めない
        },
        on: {
            init: function() {
                $('.swiper-slide.ec-keyVisualRole__slide.swiper-slide-active').addClass('animation')
            },
            slideChange: function() {
                $('.swiper-slide.ec-keyVisualRole__slide.swiper-slide-next').addClass('animation')
                $('.swiper-slide.ec-keyVisualRole__slide.swiper-slide-duplicate-next').addClass('animation')
                const prev = $('.swiper-slide.ec-keyVisualRole__slide.swiper-slide-active')
                const prevDup = $('.swiper-slide.ec-keyVisualRole__slide.swiper-slide-duplicate-active')
                if (prev) {
                    setTimeout(() => {
                        prev.removeClass('animation')
                    }, 2000)
                }
                if (prevDup) {
                    setTimeout(() => {
                        prevDup.removeClass('animation')
                    }, 2000)
                }
            }
        }
    });

    const isPlayPickSlider = $('.ec-pickupRole__slide').length > 1 ? true : false;
    const pickupSwiper = new Swiper(".ec-pickupRole__swiper", {
        direction: "vertical",
        loop: isPlayPickSlider, //ループさせる
        speed: 2000, //２秒かけながら移動
        parallax: true, //パパラックスさせる
        allowTouchMove: false, //pcではスライプの禁止、クリックでの移動を禁止する
        loopAdditionalSlides: 10, //ループのときに作られるクローンの枚数。
        autoplay: isPlayPickSlider ? {
            delay: 4000, //4秒後に次のスライドへ
            disableOnInteraction: false, //ユーザー側で操作してもスライドを止めない
        } : false,
        pagination: {
            el: ".ec-pickupRole__pagination", //ページネーションの要素
            type: "bullets", //ページネーションの種類
            clickable: true, //クリックに反応させる
            dynamicBullets: true,
            dynamicMainBullets: 1,
        },
        on: {
            init: function() {
                const link = $('.swiper-slide.ec-pickupRole__slide.swiper-slide-active a').attr('href')
                $('#ec-pickupRole__over-link').attr('href', link)
            },
            slideChangeTransitionStart: function() {
                const link = $('.swiper-slide.ec-pickupRole__slide.swiper-slide-duplicate-active a').attr('href')
                $('#ec-pickupRole__over-link').attr('href', link)
            }
        }
    });

    const isPlayTitleSlider = $('.ec-pickupRole__titleSlide').length > 1 ? true : false;
    const pickupTitleSwiper = new Swiper(".ec-pickupRole__titleSwiper", {
        loop: isPlayTitleSlider, //ループさせる
        speed: 2000, //２秒かけながら移動
        effect: "fade",
        // fadeEffect: {
        //     crossFade: true,
        // },
        parallax: true, //パパラックスさせる
        allowTouchMove: false, //pcではスライプの禁止、クリックでの移動を禁止する
        loopAdditionalSlides: 10, //ループのときに作られるクローンの枚数。
    });

    pickupTitleSwiper.controller.control = pickupSwiper; //メインスライダーとナビの紐付け
    pickupSwiper.controller.control = pickupTitleSwiper; //ナビとメインスライダーの紐付け

    // swiper画像をスクロールパララックスさせる
    // パララックスで移動させる距離
    const parallaxVal = 2;

    // パララックスのターゲット取得
    const target = $(".js-parallax");
    const targetWrap = $(".js-parallax-wrap");
    if (target.length && targetWrap.length) {
        const targetWrapTop = targetWrap ? targetWrap.offset().top : 0;
        const minusMargin = targetWrapTop / parallaxVal;

        targetWrap.css("transform", `translate3d(0px, -${minusMargin}px, 0px)`);
        $(window).on("load scroll", () => {
            const scrollTop = $(window).scrollTop();
            const y = scrollTop / parallaxVal;

            const scrollBtm = scrollTop + $(window).height();
            const targetBtm = targetWrapTop + targetWrap.height();
            if (scrollBtm > targetWrapTop && scrollTop < targetBtm) {
                target.css("transform", `translate3d(0px, ${y}px, 0px)`);
            }
        });
    }
};

const resize = () => {
    const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    if (state.breakpoints.sm >= width) {
        unmountSlickInformation();
    } else {
        if (!$informationSlickEl) {
            mountSlickInformation();
        }
    }
};

// お知らせ
let $informationSlickEl = null;
const mountSlickInformation = () => {
    unmountSlickInformation();
    $informationSlickEl = $(".ec-informationRole__list");
    const $items = $informationSlickEl.find(".ec-informationRole__item");

    // 投稿数4以上のみslick適用
    if ($items.length > 4) {
        $informationSlickEl.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            dots: true,
            speed: 500,
            arrows: false,
            cssEase: "ease-in-out",
            appendDots: ".ec-informationRole__slideDots",
            dotsClass: "el-slideDots",
            variableWidth: true,
        });
    }
};
const unmountSlickInformation = () => {
    if ($informationSlickEl && $informationSlickEl.hasClass("slick-slider")) {
        $informationSlickEl.slick("unslick");
        $informationSlickEl = null;
    }
};

const getUrlPickUp = () => {
    const $aTag = $(".ec-pickupRole .slick-active a");
    if (!$aTag.length) {
        return "";
    }

    return $aTag.attr("href");
};

document.addEventListener("DOMContentLoaded", () => created());
window.addEventListener("load", () => mounted(), { passive: true });
window.addEventListener("resize", () => resize(), { passive: true });
window.dispatchEvent(new Event("resize"));